.long-input {
    width: 500px;
}

.short-input {
    width: 300px;
}

/* flash */
.sticky {
    position: sticky;
    top: 0;
    z-index: 10;
}

/* modal */
.modal-ul {
    list-style: disc;
    padding: 10px;
}

#modal-root {
    position: fixed;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.4);
    left : 0;
    top: 0;
    width: 0px;
    height : 0px;
    opacity: 0;
    transition: opacity 0.15s ease-out, width 0s linear 0.15s, height 0s linear 0.15s;
  }
  
  #modal-root.visible {
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 0.15s ease-out;
  }

  /* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }