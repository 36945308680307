@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;700&display=swap');

/*SMALL SIGN (416x624)*/

#sign-container {
    height: 416px;
    width: 624px;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 700;
}

#sign-container p, #sign-container h1, #sign-container h2, #sign-container h3 {
    font-weight: 700;
    line-height: 1.25em;
}

#sign-container p  {
    font-size: 0.9em;
}

#sign-container h2 {
    font-size: 1.8em;
}

#sign-container h3 {
    font-size: 0.8em;
    letter-spacing: 0.25em;
}

.slim {
    font-weight: 300;
} 

#digital-sign-head {
    --top-color: #fefcf8;
    --bottom-color: #fefcf8;
    background-image: linear-gradient(var(--top-color), var(--bottom-color));
    --font-color: #e99b00;
    color: var(--font-color);
    width: 100%;
    height: 25%;
}

#digital-sign-body {
    --top-color: #e99b00;
    --bottom-color: #e5ba6f;
    background-image: linear-gradient(var(--top-color), var(--bottom-color));
    width: 100%;
    height: 75%;
    position: relative;
}

#head-content, #body-content {
    padding: 3% 4%;
}

#head-content h1 {
    font-size: 3em;
}

#body-content h1 {
    font-size: 2.6em;
}

#body-content #OH-shift {
    padding-top: 2.75em;
    display: inline-block;
}

#body-content #OH-shift h1 {
    font-size: 3.7em;
}

#body-content #OH-shift p {
    padding-top: 1em;
}

#current-shift {
    --main-color: #fefcf8;
    --secondary-color: #fefcf8;
    & .main {
        color: var(--main-color);
    }
    & .secondary {
        color: var(--secondary-color);
    }
}

#body-blob {
    padding-right: 14%;
}

#shifts {
    width: 100%;
    padding-top: 4%;
    display: flex;
    align-items: flex-start;
}

.staffer-bio {
    display: inline-block;
    width: 13%;
    margin-right: 1%;
}

.staffer-photo {
    width: 100%;
    height: 100px;
    object-fit: cover;
}

.staffer-bio p {
    text-align: center;
    overflow-wrap: break-word; 
    word-wrap: break-word; 
    word-break: break-word; 
}

.staffed-hours {
    display: inline-block;
    margin-left: 2%;
}

#next-shift {
    position: absolute;
    bottom: 0;
    height: 25%;
    --font-color: #f9deb5;
    color: var(--font-color);
}

/*LARGE SIGN (1080X192)*/

#sign-container-large {
    height: 1080px;
    width: 1920px;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 700;
}

#sign-container-large p, #sign-container-large h1, #sign-container-large h2, #sign-container-large h3 {
    font-weight: 700;
    line-height: 1.25em;
}

#sign-container-large p  {
    font-size: 2.25em;
}

#sign-container-large h2 {
    font-size: 3.5em;
}

#sign-container-large h3 {
    font-size: 1.5em;
    letter-spacing: 0.25em;
}

#sign-container-large .slim {
    font-weight: 300;
} 

#sign-container-large #head-content, #sign-container-large #body-content {
    padding: 2% 3%;
}

#sign-container-large #head-content h1 {
    font-size: 8em;
}

#sign-container-large #body-content h1 {
    font-size: 7.5em;
}

#OH-shifts {
    width: 100%;
}

#OH-shifts #next-shift {
    display: inline-block;
    position: static;
    margin-left: 5%;
}

#staffers {
    padding-top: 2%;
    --main-color: #63196a;
    --secondary-color: #9634a2;
    width: 100%;
    & .main {
        color: var(--main-color);
    }
    & .secondary {
        color: var(--secondary-color);
    }
}

#OH-staffer-bios {
    display: flex;
    gap: 60px;
    padding-top: 1%;
}

.OH-staffer-bio {
    width: 180px;
}

.OH-staffer-photo {
    width: 100%;
    height: 180px;
    object-fit: cover;
}

#staffers .OH-staffer-bio .staffer-name {
    font-weight: 300;
    font-size: 2em;
    text-align: center;
}

.placeholder-photo {
    --color: black;
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: var(--color);
}
