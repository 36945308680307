/* CSS FOR MOBILE AND OTHER FIXES */

/* HOME PAGE */

.next-shift-box{
    background: rgba(0, 0, 0, 0.16);
    color: white;
    padding: 16px;
    border-radius: 4px;
    border-left: 1px solid rgba(0, 0, 0, 0.24);
    border-bottom: 1px solid rgba(0, 0, 0, 0.24);
}

.clock-in {
    background-color: white;
    color: hsl(217 71% 53%);
    border: 1px solid white;
    padding: 8px 16px;
    margin: 24px 0 0 0;
    border-radius: 4px;
    display: flex;
    transition-duration: 0; /*overriding a property*/
}

.clock-in:hover {
    border: 1px solid #274584;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.24);
    color: #3298dc;
}


@media only screen and (min-width: 600px) {
    .home-header {
        padding: 40px 48px;
        gap: 32px
    }

}

@media only screen and (min-width: 800px) {
    .home-header {
        grid-template-columns: 1fr 1fr;
        align-items: start;
    }
}

/* main part */

.home-section{
    padding: 24px;
}

.section-title-group .shift-pool-image {
    height: 3em;
    padding: 0;
    margin-right: 16px;
}

.section-title{
    margin: 0 0 24px 0!important;
    font-size: 2.5em;
    font-family: Roboto;
}

.shift-block{
    display: grid;
    grid-template-columns: minmax(auto, 80px) 1fr;
    gap: 8px;
    align-items: center;
    margin-bottom: 24px;
}

.shift-block:last-of-type{
    margin: 0;
}

.pagination-is-rouned{
    margin-top: 32px !important;
}

.shift-date > p {
    text-align: center;
    color: darkgray;
}

.upcoming-shift-text:last-of-type{
    margin-bottom: 16px;
}

.shift-controls{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: flex-start;
}

.button.is-info:hover{
    background-color: hsl(217 71% 53%);
}

@media only screen and (min-width: 600px) {
    .home-main {
        padding: 16px 24px;
    }
}

@media only screen and (min-width: 800px) {
    .home-main {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: start;
        gap: 24px;
    }
}

/* SCHEDULE PAGE */

.container{
    padding: 24px;
    width: 100%;
    max-width: 100%;
}
/* overriding bulma */
.container:not(.is-max-desktop){
    max-width: 100%;
}
/* overriding bulma */
.container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1500px;
}

.container .title{
    margin: 0 0 24px 0;
}

.schedule-block{
    margin: 0;
}

.full-calendar{
    margin: 0;
    display: grid;
    gap: 24px;
}

.legend-groups{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 16px;
    gap: 24px;
    width:100%;
}

.legend-groups h3{
    color: #363636;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 16px;
}

.legend-groups div div{
    margin-bottom: 8px;
}

.calendar{
    overflow: scroll;
}

.field button{
    color: white;
    background-color: #2C3E50;
}

.field button:hover{
    background-color: #79828B;
    color: white;
}

.calendar .card-content{
    padding: 0;
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr{
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 8px;
}

.fc-scrollgrid-sync-inner{
    text-align: center;
}

.fc-toolbar-chunk:nth-of-type(2){
    grid-row: 1/2;
    text-align: center;
}

.fc-toolbar-chunk:first-of-type{
    margin: auto;
}

.fc-toolbar-chunk:last-of-type{
    margin: auto;
}

@media only screen and (min-width: 400px) {
    .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr{
        grid-template-columns: 1fr 1fr;
    }
    
    .fc-toolbar-chunk:nth-of-type(2){
        grid-column: 1/3;
    }
    
    .fc-toolbar-chunk:first-of-type{
        justify-self: start;
        margin-left: 0;
    }
    
    .fc-toolbar-chunk:last-of-type{
        justify-self: end;
        margin-right: 0;
    }
}

@media only screen and (min-width: 550px) {
    .container .title {
        margin: 0 0 48px 0;
    }


    .full-calendar{
        grid-template-columns: 136px 1fr;
    }
    
    .legend-groups{
        grid-template-columns: 1fr;
    }
}

@media only screen and (min-width: 800px) {
    .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr{
        grid-template-columns: auto 1fr auto;
    }
    
    .fc-toolbar-chunk:nth-of-type(2){
        grid-column: 2/3;
    }
}

@media only screen and (min-width: 936px) {
    .container{
        padding: 48px;
    }

    .schedule-block{
        padding-bottom: 24px;
    }
}


@media only screen and (min-width: 1024px) {
    .full-calendar{
        grid-template-columns: 152px 1fr;
    }
}

/* MANAGE SCHEDULE */

.field {
    margin-bottom: 16px;
}

.field:not(:last-child) {
    margin-bottom: 16px;
}

.field.is-grouped {
    flex-wrap: wrap;
    gap: 24px;
    margin-bottom: 32px;
    align-items: center;
}

h2.title {
    font-size: 1.7rem;
}

@media only screen and (min-width: 550px){
    .container h2.title {
        margin: 0 0 24px 0;
    }
}

/* EMPLOYEES */

.help-message{
    font-style: italic;
    margin-bottom: 16px;
    font-size: 12px;
}


.field.is-grouped>.control:not(:last-child){
    margin: 0;
}

.dataTables_length{
    margin-bottom:8px;
}

div.dataTables_wrapper div.dataTables_filter {
    text-align: left;
}

th {
    background-color: hsl(217, 71%, 53%);
}

.table thead tr .sorting:nth-of-type(1) {
    width: 71.9531px !important;
}

.table thead tr .sorting:nth-of-type(2) {
    width: 134.234px !important;
}

.table thead tr .sorting:nth-of-type(3) {
    width: 55.625px !important;
}

.table thead tr .sorting:nth-of-type(4) {
    width: 189.203px !important;
}

.table thead tr .sorting:nth-of-type(5) {
    width: 60.8438px !important;
}

.table thead tr .sorting:nth-of-type(6) {
    width: 51.1719px !important;
}

.table thead tr .sorting:nth-of-type(7) {
    width: 89.1406px !important;
}


.dataTables_scrollHeadInner, table.is-striped.dataTable.no-footer{
    width: 100% !important;
    min-width: 922.09px !important;
    max-width: none;
}

.table thead tr td, .table thead tr th {
    border-width: 0 0 2px;
    color: white;
    border-right: 1px solid rgba(255, 255, 255, 0.146);
}

table.dataTable>thead .sorting:after, 
table.dataTable>thead .sorting_asc:after, 
table.dataTable>thead .sorting_desc:after, 
table.dataTable>thead .sorting_asc_disabled:after, 
table.dataTable>thead .sorting_desc_disabled:after {
    right: 0.2em;
    content: "↓";
}

table.dataTable>thead .sorting:before, table.dataTable>thead .sorting:after, table.dataTable>thead .sorting_asc:before, table.dataTable>thead .sorting_asc:after, table.dataTable>thead .sorting_desc:before, table.dataTable>thead .sorting_desc:after, table.dataTable>thead .sorting_asc_disabled:before, table.dataTable>thead .sorting_asc_disabled:after, table.dataTable>thead .sorting_desc_disabled:before, table.dataTable>thead .sorting_desc_disabled:after {
    position: absolute;
    top: 8px;
    opacity: .3;
}


@media only screen and (min-width: 550px){
    div.dataTables_wrapper div.dataTables_filter {
        text-align: right;
    }
    .dataTables_length {
        float: left;
    }
}

@media only screen and (min-width: 1020px){
    .help-message{
        display: none;
    }
}


/* PROFILE PAGE */

label.checkbox {
    margin-right: 16px;
}

label>.input{
    margin-bottom: 16px;
    margin-top: 8px;
}

h3.label{
    font-size:1.3em;
}

form .columns .column.is-3, .column.is-4{
    padding: 0 16px;
}

.control.profile{
    margin-bottom: 16px;
}

.title.profile {
    display: inline-block;
    margin: 0;
}

.profile-header{
    margin: 0 0 24px 0;
}

.radio>input{
    margin-left: 8px;
}

.radio, .radio+.radio {
    margin: 0 16px 0 0;
}

/* HOURS PAGE */

.container .container{
    padding: 0;
}

/* ADMIN PAGE */

.checkbox input{
    margin-right: 8px;
}

.label svg{
    margin-left: 8px;
}

.card{
    margin-bottom: 16px;
}